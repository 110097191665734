import {Settings} from "@/models/Settings";

export class UpdateSettingsRequest {

    applicationBlocked: boolean;
    message: string;
    helpContent: string;

    constructor(settings: Settings) {
        this.applicationBlocked = settings.applicationBlocked;
        this.message = settings.message;
        this.helpContent = settings.helpContent;
    }

}
