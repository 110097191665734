













































































import {Component, Vue} from 'vue-property-decorator';
import {settingsService} from "@/services";
import {Settings} from "@/models/Settings";
import {VueEditor} from "vue2-editor";
import {UpdateSettingsRequest} from "@/models/request/UpdateSettingsRequest";
import {EventBus} from "@/main";
import {UserNotification} from "@/models/UserNotification";
import {UserNotificationType} from "@/models/UserNotificationType";

@Component({
  name: 'Settings',
  components: {
    VueEditor
  },
})
export default class SettingsView extends Vue {

  editDialog: boolean = false;
  editHelpDialog: boolean = false;
  settings: Settings = {} as Settings;
  updateSettingsRequest: UpdateSettingsRequest = {} as UpdateSettingsRequest;

  async created(): Promise<void> {
    await this.getSettings();
    this.cancelChanges();
  }

  async getSettings(): Promise<void> {
    this.settings = await settingsService.getSettings();
  }

  cancelChanges(): void {
    this.editDialog = false;
    this.editHelpDialog = false;
    this.updateSettingsRequest = new UpdateSettingsRequest(this.settings);
  }

  async saveSettings(): Promise<void> {
    this.editDialog = false;
    this.editHelpDialog = false;
    await settingsService.updateSettings(this.updateSettingsRequest);
    EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Ustawienia zostały zapisane'));
    await this.getSettings();
  }

}
